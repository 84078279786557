import React from "react"
import {Container} from 'react-bootstrap'
import Layout from "../components/Layout/Layout"
import HeroBanner from "../components/HeroBanner/HeroBanner"
import queryString from "query-string"
import { BiMobile, BiEnvelope, BiMap } from 'react-icons/bi';
import './contact.scss'

const ContactPage = ({location}) => {
    const {email} = queryString.parse(location.search)

    return(
        <Layout
            title="Contact Us | Guiding Digital"
            description="We'd love to hear from you. Send us a message with any questions or to find out how Guiding Digital can help you in your digital journey.">
            <HeroBanner
                headline="Contact Us"
                description="Give us a yell to see how we can help with your next project and grow your digital presence."
                className="contactHero"
            />

            <Container className="text-center contactUsContainer">
                <div className="contactUsInnerContainer">
                    <h2>Get in Touch</h2>
                    <p>
                        Complete the form or use one of the methods below to reach 
                        out to us. We look forward to hearing from you!
                    </p>

                    <hr />
                    
                    <table className="contactMethods">
                        <tr>
                            <td><BiMobile className="phoneIcon" /></td>
                            <td align="left"><a href="tel:+14797092520">(479) 709-2520</a></td>
                        </tr>
                        <tr>
                            <td><BiEnvelope className="mailIcon" /></td>
                            <td align="left"><a href="mailto:contact@guidingdigital.com">contact@guidingdigital.com</a></td>
                        </tr>
                        <tr>
                            <td valign="top"><BiMap className="locationIcon" /></td>
                            <td align="left">
                                PO Box 8053<br />
                                Springdale, AR 72766
                            </td>
                        </tr>
                    </table>
                </div>

                <div className="contactFormContainer">
                    <form name="contactForm" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/contact-success/">
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="contactForm" />
                        <div className="form-group col-xs-12">
                            <label htmlfor="name" class="control-label">Name</label> <span className="required">*</span>
                            <input className="form-control" name="name" id="name" required aria-label="Name" />
                        </div>
                        <div className="form-group col-xs-12">
                            <label htmlfor="company" class="control-label">Company</label>
                            <input className="form-control" name="company" id="company" aria-label="Company" />
                        </div>
                        <div className="row">
                            <div className="form-group col-xs-12 col-md-6">
                                <label htmlfor="email" class="control-label">Email</label> <span className="required">*</span>
                                <input className="form-control" type="email" name="email" id="email" required aria-label="Email" value={email} />
                            </div>
                            <div className="form-group col-xs-12 col-md-6">
                                <label htmlfor="phone" class="control-label">Phone</label>
                                <input className="form-control" type="phone" name="phone" id="phone" aria-label="Phone" />
                            </div>
                        </div>
                        <div className="form-group col-xs-12">
                            <label htmlfor="message" class="control-label">Message or question</label> <span className="required">*</span>
                            <textarea className="form-control" name="message" id="message" required rows="6" aria-label="Message" />
                        </div>
                        <button type="submit"className="btn btn-primary">Let's Talk!</button>
                    </form>
                </div>

            </Container>
        </Layout>
    )
}

export default ContactPage